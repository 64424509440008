import RctpProgramAccordian from "components/RCTP-Program/RctpProgramAccordian";
import TrainingSessionHero from "components/RCTP-Program/TrainingSessionHero";
import RhombusBlade from "components/common/RhombusBlade";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const TrainingSession = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;

  useEffect(() => {
    if (frontmatter.draft && window) {
      window.location.href = "/404";
    }
  }, []);

  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          {frontmatter.state} - Rhombus Certified Technical Professional
        </title>
        <meta
          name="description"
          content="Our intensive, one-day RCTP training program is your fast track to mastering the entire Rhombus product line."
        />
      </Helmet>
      <TrainingSessionHero
        image={frontmatter.image}
        state={frontmatter.state}
        trainings={frontmatter.trainingEvents}
        link={frontmatter.link}
        formId={frontmatter.formId}
      />
      <RctpProgramAccordian />
      <RhombusBlade
        fancy
        reverse
        title="Becoming a Partner is Simple"
        blurb="Submit your application online in 5 minutes, and our Partner Team will reach out within two business days."
        button={{
          text: "Apply",
          path:
            "https://partners.rhombussystems.com/prm/English/c/Terms?__hstc=158651301.11b9a359e50e4a16823c8ed728407db3.1691694797463.1692043226007.1692120531447.10&__hssc=158651301.9.1692120531447&__hsfp=3529731725",
          useHref: true,
          target: "_blank",
        }}
      />
    </GlobalLayout>
  );
};

export default TrainingSession;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        state
        image
        link
        formId
        trainingEvents {
          city
          address
          trainingDates {
            startDate
            endDate
          }
        }
      }
    }
  }
`;
